import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Modals = [
  {
    id: 1,
    openButtonLabel: "Task 1",
    title: "Agregar Linea a Metamask",
    content: "Agregar la blockchain de Linea a tu Metamask te permitirá interactuar con la red Linea Mainnet directamente desde tu billetera Metamask. La red Linea Mainnet es una cadena de bloques independiente que podría ofrecer tokens, aplicaciones descentralizadas y otras funcionalidades únicas.",
    link: "https://chainlist.org/chain/59144",
    buttonLabel: "Agregar Linea",
    expirationTime: 0, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 2,
    openButtonLabel: "Task 2",
    title: "Bridge Oficial Linea",
    content: "Nuestra segunda tarea es transferir una cantidad de $ETH desde la Ethereum Mainnet utilizando el puente oficial de Linea, como parte de nuestro proceso.",
    link: "https://bridge.linea.build/",
    buttonLabel: "Bridge Oficial",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 3,
    openButtonLabel: "Task 3",
    title: "Bridge usando Symbiosis ",
    content: "Ahora queremos utilizar algunos puentes adicionales. Hemos seleccionado la red zkSync debido a las tarifas económicas, pero puedes elegir cualquier red de origen que prefieras.",
    link: "https://app.symbiosis.finance/swap?chainIn=ZkSync%20Era&chainOut=Linea&tokenIn=ETH&tokenOut=ETH",
    buttonLabel: "Bridge Symbiosis",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 4,
    openButtonLabel: "Task 4",
    title: "Bridge usando Orbiter",
    content: "Orbiter es otro puente que podemos utilizar para transferir $ETH a la Linea Mainnet. Hemos seleccionado la red zkSync debido a las tarifas económicas, pero puedes elegir cualquier red de origen que prefieras.",
    link: "https://www.orbiter.finance/?source=zkSync%20Era&dest=Linea",
    buttonLabel: "Bridge Orbiter",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 5,
    openButtonLabel: "Task 5",
    title: "Comprar USDC on Syncswap",
    content: "En la siguiente etapa de nuestro proceso, tenemos la intención de proporcionar liquidez al par $ETH/USDc. Para ello, vamos a asegurarnos de obtener una cantidad adecuada de USDC en primer lugar, preparándonos así para llevar a cabo esta operación de manera exitosa y eficiente.",
    link: "https://syncswap.xyz/",
    buttonLabel: "Comprar USDC",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 6,
    openButtonLabel: "Task 6",
    title: "Depositar ETH y USDC en pool",
    content: "Ahora que disponemos de una cantidad de ETH y USDC , vamos a proporcionar liquidez en el pool de Syncswap implica añadir fondos al mercado, permitiendo intercambios eficientes entre activos. Los proveedores obtienen comisiones y contribuyen a la liquidez general del ecosistema.",
    link: "https://syncswap.xyz/pool",
    buttonLabel: "Depositar Liquidez",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 7,
    openButtonLabel: "Task 7",
    title: "Comprar Token $iZi",
    content: "iZumi.Finance es uno de los DEX más populares en Linea Mainnet, así que procedamos a adquirir parte de su token nativo $iZi.",
    link: "https://izumi.finance/trade/swap",
    buttonLabel: "Comprar iZi",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 8,
    openButtonLabel: "Task 8",
    title: "Comprar Token WETH",
    content: "De esta manera, en el próximo paso podremos añadir liquidez. Intercambia una parte de tus $ETH por $WETH por un valor aproximado al que gastaste en $iZi en el paso anterior.",
    link: "https://izumi.finance/trade/swap",
    buttonLabel: "Comprar WETH",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 9,
    openButtonLabel: "Task 9",
    title: "Depositar iZi y WETH en pool",
    content: "Ahora que tenemos tanto $iZi como $WETH, agreguemos liquidez a un pool en iZumi. Ten en cuenta que la cantidad y el tiempo que decidas dejarla en iZumi dependen completamente de ti.",
    link: "https://izumi.finance/trade/add-liquidity/?token0=0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747&token1=0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f&fee=3000&chainId=59144&range=undefined",
    buttonLabel: "Depositar Liquidez",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
 
  
  // Agregar más objetos para más modales...
];

const AirdropLinea = () => {
  const [showModal, setShowModal] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);

  const openModal = (modalId) => {
    setShowModal(modalId);
  };

  const closeModal = () => {
    setShowModal(null);
  };

  const toggleCompletedModal = (modalId) => {
    const now = new Date().getTime();
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.includes(modalId)
        ? prevCompletedTasks.filter((taskId) => taskId !== modalId)
        : [...prevCompletedTasks, modalId];
      if (!prevCompletedTasks.includes(modalId)) {
        const task = Modals.find((modal) => modal.id === modalId);
        if (task) {
          task.completedTime = now; 
          localStorage.setItem("completedTasksLinea", JSON.stringify(updatedTasks));
        }
      }
      return updatedTasks;
    });
  };

  const checkExpiredTasks = () => {
    const now = new Date().getTime();  
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.filter((taskId) => {
        const task = Modals.find((modal) => modal.id === taskId);
        if (!task) return false;
        return now - task.completedTime < task.expirationTime;
      });
      localStorage.setItem("completedTasksLinea", JSON.stringify(updatedTasks));
      return updatedTasks;
    });
  };

  useEffect(() => {
    const savedCompletedTasks = JSON.parse(localStorage.getItem("completedTasksLinea") || "[]");
    setCompletedTasks(savedCompletedTasks);
    checkExpiredTasks(); 

    
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkExpiredTasks();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(checkExpiredTasks, 60 * 60 * 1000); // Verificar cada segundo
    return () => {
      clearInterval(interval);
    };
  }, []);

  const closeCompletedModal = () => {
    closeModal(); 
  };


  return (
    <div>
      <h1 className="airdrop-Name">Linea</h1>
      <p className="airdrop-Description">
      Linea amplía Ethereum. Compatibilidad con la Máquina Virtual Ethereum. Aplicaciones existentes y nuevas a menor costo. Acceso comunitario a apps a menor precio y mayor velocidad. Usa criptografía cuántica y pruebas de conocimiento cero. Registra transacciones en red y consolida en Mainnet. Costos reducidos y seguridad mantenida.
      </p>
      <div className="modal-container">
        {Modals.map((modal) => (
          <div key={modal.id} className="modal-item">
            <button
              className="btn btn-primary modal-button"
              onClick={() => openModal(modal.id)}
              style={{
                width: "150px", 
                height: "40px",
                boxShadow: completedTasks.includes(modal.id)
                  ? "0 0 0 1px #00ff00" // Borde verde de 1 píxel
                  : "",
              }}
            >
              {modal.openButtonLabel}
            </button>
          </div>
        ))}
      </div>

      {showModal !== null && (
        <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">{Modals[showModal - 1].title}</h1>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Cerrar"></button>
              </div>
              <div className="modal-body">
                <strong>{Modals[showModal - 1].content}</strong>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-success ${completedTasks.includes(showModal) ? "green-button" : ""}`}
                  onClick={() => {
                    toggleCompletedModal(showModal)
                    closeCompletedModal();
                  }}
                >
                  {completedTasks.includes(showModal)
                    ? Modals[showModal - 1].uncompletedButtonLabel
                    : Modals[showModal - 1].completedButtonLabel}
                </button>
                <Link
                  to={Modals[showModal - 1].link}
                  target="_blank"
                  className="btn btn-primary"
                >
                  {Modals[showModal - 1].buttonLabel}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirdropLinea;
