import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Modals = [
  {
    id: 1,
    openButtonLabel: "Task 1",
    title: "Agregar zkSync a Metamask",
    content: "Agregar la blockchain de ZKSync a tu Metamask te permitirá interactuar con la red zkSync Mainnet directamente desde tu billetera Metamask. La red zkSynk Mainnet es una cadena de bloques independiente que podría ofrecer tokens, aplicaciones descentralizadas y otras funcionalidades únicas.",
    link: "https://chainlist.org/chain/324",
    buttonLabel: "Agregar zkSynk",
    expirationTime: 0, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 2,
    openButtonLabel: "Task 2",
    title: "Bridge Oficial zkSynk",
    content: "Aunque tanto Ethereum (L1) como ZKsync (L2) utilizan $ETH para pagar tarifas de gas, primero debes (trasladar) una cantidad desde Ethereum a zkSync mediante el puente oficial. ¡NO ENVÍES TODO TU $ETH! Utilizarás otros puentes en el próximo paso y necesitarás al menos 0.005 $ETH en cada uno (además de suficiente para gas, por lo que recomendamos un total de > 0.01 $ETH) en Ethereum para hacerlo.",
    link: "https://portal.zksync.io/",
    buttonLabel: "Bridge Oficial",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 3,
    openButtonLabel: "Task 3",
    title: "Bridge usando Orbiter",
    content: "Utiliza el puente de Orbiter Finance para trasladar $ETH desde la red Ethereum a zkSync. Este proceso permitirá beneficiarte de la escalabilidad y tarifas reducidas en la capa 2. Asegúrate de tener suficiente $ETH para cubrir las tarifas de gas y futuros pasos que requieran fondos en Ethereum.",
    link: "https://www.orbiter.finance/?source=Ethereum&dest=zkSync%20Era",
    buttonLabel: "Bridge Orbiter",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 4,
    openButtonLabel: "Task 4",
    title: "Bridge usando Bungee",
    content: "Queremos utilizar varios puentes opcionales, así que dirijamos más $ETH desde Ethereum a zkSync utilizando Bungee",
    link: "https://www.bungee.exchange/",
    buttonLabel: "Bridge Bungee",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 5,
    openButtonLabel: "Task 5",
    title: "Comprar $MUTE en Mute.Switch",
    content: "Mute es uno de los principales DEX en zkSync, así que adquiramos parte de su token nativo. NOTA: Mute puede presentar fallos y las transacciones pueden fallar antes de que puedas confirmarlas en tu billetera. Espera 30 minutos, inténtalo nuevamente y finalmente podrás completar la transacción.",
    link: "https://app.mute.io/swap?inputCurrency=0x5aea5775959fbc2557cc8789bc1bf90a239d9a91&outputCurrency=0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42",
    buttonLabel: "Comprar $MUTE",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 6,
    openButtonLabel: "Task 6",
    title: "Depositar  $MUTE/$ETH en pool",
    content: "Ahora que disponemos de una cantidad de MUTE y ETH, vamos a proporcionar liquidez en el pool de Mute.Switch implica añadir fondos al mercado, permitiendo intercambios eficientes entre activos. Los proveedores obtienen comisiones y contribuyen a la liquidez general del ecosistema.",
    link: "https://app.mute.io/pool/0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42/0x5aea5775959fbc2557cc8789bc1bf90a239d9a91/false",
    buttonLabel: "Agregar Liquidez",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 7,
    openButtonLabel: "Task 7",
    title: "Comprar USDC on Syncswap",
    content: "Deseamos utilizar distintos DEX en zkSync, así que intercambiemos algunos $ETH por $USDC en syncswap.xyz.",
    link: "https://syncswap.xyz/?outputCurrency=0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4",
    buttonLabel: "Comprar USDC",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 8,
    openButtonLabel: "Task 8",
    title: "Devolvemos USDC a ETH",
    content: "Agreguemos otra transacción completando el ciclo al intercambiar los $USDC de regreso a $ETH. Primero, necesitamos aprobar el gasto de $USDC.",
    link: "https://syncswap.xyz/?inputCurrency=0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4&outputCurrency=0xd8bd59549a0b741934e157ec299b65463b3b944f",
    buttonLabel: "Comprar ETH",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 8,
    openButtonLabel: "Task 8",
    title: "Devolvemos USDC a ETH",
    content: "Agreguemos otra transacción completando el ciclo al intercambiar los $USDC de regreso a $ETH. Primero, necesitamos aprobar el gasto de $USDC.",
    link: "https://syncswap.xyz/?inputCurrency=0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4&outputCurrency=0xd8bd59549a0b741934e157ec299b65463b3b944f",
    buttonLabel: "Comprar ETH",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 9,
    openButtonLabel: "Task 9",
    title: "Compramos $SPACE",
    content: "Compra el token $SPACE. Ahora deseamos adquirir más tokens de DEX, esta vez en spacefi.io.",
    link: "https://swap-zksync.spacefi.io/#/swap",
    buttonLabel: "Comprar $SPACE",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 10,
    openButtonLabel: "Task 10",
    title: "Depositar ETH y SPACE en pool",
    content: "Con los fondos en ETH y SPACE que tenemos, es momento de aportar liquidez al pool de spacefi.io. Esta acción aumentará la eficacia de los intercambios entre activos y los proveedores ganarán comisiones, enriqueciendo la liquidez general del ecosistema.",
    link: "https://swap-zksync.spacefi.io/#/add/ETH/0x47260090cE5e83454d5f05A0AbbB2C953835f777",
    buttonLabel: "Depositar Liquidez",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 11,
    openButtonLabel: "Task 11",
    title: "Farmear Tokens",
    content: "Después de proveer liquidez, nuestra intención es incorporarla a la Farm para maximizar recompensas ganadas. Ahora vamos a potenciar aún más nuestros rendimientos a través de esta estrategia de participación activa en el protocolo.",
    link: "https://app.spacefi.io/#/farm",
    buttonLabel: "Comenzar a Farmear",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 12,
    openButtonLabel: "Task 12",
    title: "Registrar Dominio",
    content: "Aprovecha las ventajas de la tecnología zkRollup registrando un dominio .zk en zkns. Al hacerlo, garantizas la escalabilidad y eficiencia de Ethereum. Ingresa un dominio en zkns, y tras confirmar su disponibilidad, solicita el registro usando ETH para potenciar tu presencia en línea.",
    link: "https://app.zkns.domains/",
    buttonLabel: "Registra tu Dominio",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 13,
    openButtonLabel: "Task 13",
    title: "Mintear NFT",
    content: "Crea un NFT en Mintsquare. Prepara una imagen (puede ser propia o de uso libre). Abre el enlace y elige zkSync Era como red. Sube la foto, nómbrala como desees y luego procede a Mint para completar la creación del NFT.",
    link: "https://mintsquare.io/",
    buttonLabel: "Mintear NFT",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 14,
    openButtonLabel: "Task 14",
    title: "Comprar SIS",
    content: "Symbiosis es un DEX compatible con zkSync, así que adquiramos su token.",
    link: "https://app.symbiosis.finance/swap?chainIn=ZkSync%20Era&chainOut=ZkSync%20Era&tokenIn=ETH&tokenOut=0xdd9f72afED3631a6C85b5369D84875e6c42f1827",
    buttonLabel: "Comprar SIS",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 15,
    openButtonLabel: "Task 15",
    title: "Comprar USDC en Maverick",
    content: "Aprovechemos la plataforma emergente Maverick en zkSync para adquirir $USDC. Esta creciente DEX ofrece oportunidades para ampliar tus activos en un entorno escalable y eficiente como zkSync.",
    link: "https://app.mav.xyz/?chain=324&tokenB=0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4&tokenA=ETH",
    buttonLabel: "Comprar USDC",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 16,
    openButtonLabel: "Task 16",
    title: "Vender USDCD en Maverick",
    content: "Vamos a ejecutar otra transacción en Maverick al vender los $USDC que acabamos de adquirir, convirtiéndolos nuevamente a $ETH. Esta acción amplía tu experiencia en la plataforma y refuerza tus intercambios en zkSync.",
    link: "https://app.mav.xyz/?chain=1&tokenB=ETH&tokenA=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    buttonLabel: "Vender USDC",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 17,
    openButtonLabel: "Task 17",
    title: "Comprar VC en Velocore",
    content: "Velocore es un DEX establecido en zkSync. Compremos una cantidad de su token $VC para prepararnos y estar listos para contribuir con liquidez en su plataforma, generando oportunidades en este entorno de escalabilidad y eficiencia.",
    link: "https://zksync.velocore.xyz/swap",
    buttonLabel: "Comprar VC",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 18,
    openButtonLabel: "Task 18",
    title: "Comprar USDC en Velocore",
    content: "Una vez que hemos asegurado tanto $USDC como $VC en nuestra billetera, es el momento de prepararlos y luego depositarlos en un pool de liquidez. Esta acción optimizará nuestra participación en el ecosistema financiero descentralizado.",
    link: "https://zksync.velocore.xyz/liquidity/0xf746d0bed4a9707fade68855bad3e3f45f4e3da1",
    buttonLabel: "Agregar Liquidez",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  // Agregar más objetos para más modales...
];

const AirdropzkSync = () => {
  const [showModal, setShowModal] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);

  const openModal = (modalId) => {
    setShowModal(modalId);
  };

  const closeModal = () => {
    setShowModal(null); 
  };

  const toggleCompletedModal = (modalId) => {
    const now = new Date().getTime();
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.includes(modalId)
        ? prevCompletedTasks.filter((taskId) => taskId !== modalId)
        : [...prevCompletedTasks, modalId];
      if (!prevCompletedTasks.includes(modalId)) {
        const task = Modals.find((modal) => modal.id === modalId);
        if (task) {
          task.completedTime = now;
          localStorage.setItem("completedTaskszkSync", JSON.stringify(updatedTasks));
        }
      }
      return updatedTasks;
    });
  };

  const checkExpiredTasks = () => {
    const now = new Date().getTime();
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.filter((taskId) => {
        const task = Modals.find((modal) => modal.id === taskId);
        if (!task) return false;
        return now - task.completedTime < task.expirationTime;
      });
      localStorage.setItem("completedTaskszkSync", JSON.stringify(updatedTasks));
      return updatedTasks;
    });
  };

  useEffect(() => {
    const savedCompletedTasks = JSON.parse(localStorage.getItem("completedTaskszkSync") || "[]");
    setCompletedTasks(savedCompletedTasks);
    checkExpiredTasks();

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkExpiredTasks();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(checkExpiredTasks, 60 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const closeCompletedModal = () => {
    closeModal(); // Cierra el modal
  };

  return (
    <div>
      <h1 className="airdrop-Name">zkSynk</h1>
      <p className="airdrop-Description">
      zkSync Era representa un protocolo de Capa 2 que lleva a Ethereum a nuevas cimas mediante tecnología ZK de vanguardia. Su misión trasciende la simple expansión del rendimiento de Ethereum; se dedican a preservar sus valores fundamentales: libertad, auto-soberanía y descentralización, llevándolos a una dimensión ampliada.
      </p>
      <div className="modal-container">
        {Modals.map((modal) => (
          <div key={modal.id} className="modal-item">
            <button
              className="btn btn-primary modal-button"
              onClick={() => openModal(modal.id)}
              style={{
                width: "150px", 
                height: "40px",
                boxShadow: completedTasks.includes(modal.id)
                  ? "0 0 0 1px #00ff00"
                  : "",
              }}
            >
              {modal.openButtonLabel}
            </button>
          </div>
        ))}
      </div>

      {showModal !== null && (
        <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">{Modals[showModal - 1].title}</h1>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Cerrar"></button>
              </div>
              <div className="modal-body">
                <strong>{Modals[showModal - 1].content}</strong>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-success ${completedTasks.includes(showModal) ? "green-button" : ""}`}
                  onClick={() => {
                    toggleCompletedModal(showModal);
                    closeCompletedModal(); // Cierra el modal al marcar la tarea como completada
                  }}
                >
                  {completedTasks.includes(showModal)
                    ? Modals[showModal - 1].uncompletedButtonLabel
                    : Modals[showModal - 1].completedButtonLabel}
                </button>
                <Link
                  to={Modals[showModal - 1].link}
                  target="_blank"
                  className="btn btn-primary"
                >
                  {Modals[showModal - 1].buttonLabel}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirdropzkSync;
