import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import NFTContractABI from '../NFTContractABI.json';

import criptofenixImage from '../Imagenes/criptofenix-nft.png';

import './MetamaskLogin.css';
import './abnes-font.css';

const MetamaskLogin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [account, setAccount] = useState('');
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkMetamaskConnection();
    setupMetamaskListeners();
  }, []);

  const setupMetamaskListeners = () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length > 0) {
      setAccount(accounts[0]);
      setShowSearchButton(true);
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      setAccount('');
      setShowSearchButton(false);
    }
  };

  const handleChainChanged = (chainId) => {
    setCurrentNetwork(chainId);
  };

  const checkMetamaskConnection = async () => {
    if (window.ethereum && window.ethereum.selectedAddress) {
      setAccount(window.ethereum.selectedAddress);
      setShowSearchButton(true);
      setLoggedIn(true);

      const network = await window.ethereum.request({ method: 'eth_chainId' });
      setCurrentNetwork(network);
    } else {
      setLoggedIn(false);
      setAccount('');
      setShowSearchButton(false);
    }
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        checkMetamaskConnection();
      } catch (error) {
        console.error('Error al conectar con Metamask:', error);
      }
    } else {
      console.error('Metamask no está instalado en este navegador.');
    }
  };

  const disconnectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'wallet_requestPermissions', params: [{ eth_accounts: {} }] })
        .then(() => {
          setLoggedIn(false);
          setAccount('');
          setShowSearchButton(false);
        })
        .catch(error => {
          console.error('Error al desconectar de Metamask:', error);
        });
    }
  };

  const searchNFT = async () => {
    if (loggedIn && currentNetwork === '0x89') {
      const web3 = new Web3(window.ethereum);
      const nftContractAddress = '0x16e2e016EA86dF3050C0C503424B6B035B74F82C';
      const abiArray = Array.isArray(NFTContractABI) ? NFTContractABI : JSON.parse(NFTContractABI);
      const nftContract = new web3.eth.Contract(abiArray, nftContractAddress);

      try {
        const nftBalance = await nftContract.methods.balanceOf(account).call();
        if (parseInt(nftBalance) > 0) {
          alert('NFT Verificado Correctamente');
          navigate('/airdrops');
        } else {
          alert('NFT Requerido');
        }
      } catch (error) {
        console.error('Error al comprobar el NFT:', error);
      }
    }
  };

  const getFormattedAddress = () => {
    if (account && account.length >= 10) {
      const firstFour = account.slice(0, 4);
      const lastFour = account.slice(-4);
      return `${firstFour}...${lastFour}`;
    }
    return account;
  };

  function connectToPoligon() {
    if (window.ethereum) {
      window.ethereum.request({ method: 'wallet_connect' });
    } else {
      console.error('Metamask no está instalado en este navegador.');
    }
  }

  return (
    <div>
      <div className="Background-login"></div>
      <div className="page-container">
        <div className="container-wrapper">
          <div className="container">
            <h1 className="title">Iniciar Sesión con Metamask</h1>
            {loggedIn ? (
              <div>
                <div>
                  <p className="logged-in-message">
                    Usuario conectado con Metamask: <span className="connected-account">{getFormattedAddress()}</span>
                  </p>
                  <button className="button" onClick={disconnectMetamask}>
                    Desconectar
                  </button>
                  <div className="image-container">
                    <img src={criptofenixImage} alt="Criptofenix NFT" className="nft-image" />
                  </div>
                  {showSearchButton && currentNetwork === '0x89' && (
                    <button className="button button--secondary" onClick={searchNFT}>
                      Validar NFT
                    </button>
                  )}
                </div>
                {currentNetwork !== '0x89' && (
                  <div>
                    <button className="button" onClick={connectMetamask}>
                      Conectar a Poligon
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <button className="button" onClick={connectMetamask}>
                  Conectar con Metamask
                </button>
                <div className="image-container">
                  <img src={criptofenixImage} alt="Criptofenix NFT" className="nft-image" />
                </div>
                <p className="texto-debajo-imagen">¡IMPORTANTE!<br/> Ahora puedes acceder a la página de Airdrops de Cripto Fénix con el NFT oficial.</p>
              </div>
            )}
          </div>
        </div>
        <div className="text-container">
          <p className="white-text">
            CriptoFénix es el mejor lugar para sumergirte en el mundo de las criptomonedas.
            <br /><br />
            Nuestra *MISION* es mantenemos al día en temas como DeFi, NFTs, Blockchain, Inversiones y mucho más.
            <br /><br />
            Con el apoyo del equipo de CriptoFénix y los propios usuarios, el viaje por el universo cripto es más sencillo y rentable.
            <br /><br />
            Además, trabajamos con partners para potenciar nuestro conocimiento y crecimiento.
            <br /><br />
            Si te interesa lo que acabas de leer, únete a nosotros: ----- LINK
          </p>
        </div>
      </div>
    </div>
  );
};

export default MetamaskLogin;
