import React from 'react';
import './terms.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-page">
      <h1>Términos & Condiciones</h1>
      <p>
        Bienvenido a CriptoFénix. Los siguientes términos y condiciones ("Términos") rigen su uso de este Sitio web. Al
        acceder, ver o utilizar el contenido, material, productos o servicios disponibles en o a través de este Sitio web,
        usted indica que ha leído y comprendido estos Términos, que está de acuerdo con ellos y tiene la intención de
        quedar legalmente obligado por ellos. Si no está de acuerdo con estos Términos, no se le otorga permiso para usar
        este Sitio web y debe salir inmediatamente.
      </p>
      <p>
        <strong>Prestación de Servicios</strong>
        <br />
        1.1 Este Sitio web proporciona una plataforma para conectar billeteras de Ethereum Virtual Machine (EVM) a CriptoFénix ("Servicios").
        <br />
        1.2 Usted acepta utilizar los Servicios solo para fines permitidos por estos Términos y cumplir con todas las leyes, regulaciones y prácticas o directrices generalmente aceptadas en las jurisdicciones relevantes.
      </p>
      <p>
        <strong>Sus Obligaciones</strong>
        <br />
        2.1 Como condición para usar nuestros Servicios, usted acepta no hacer un uso indebido de nuestros Servicios.
        <br />
        2.2 Usted es responsable de todas sus actividades en relación con los Servicios.
      </p>
      <p>
        <strong>Derechos de Propiedad Intelectual</strong>
        <br />
        3.1 Todo el contenido y la funcionalidad del Sitio web, incluido texto, gráficos, logotipos, iconos, imágenes y videos, así como su selección y disposición, son propiedad exclusiva de CriptoFénix y están protegidos por las leyes internacionales de derechos de autor. Todos los derechos no otorgados expresamente quedan reservados.
      </p>
      <p>
        <strong>Política de Privacidad</strong>
        <br />
        4.1 Nuestra <a href="https://www.criptofenix.com/privacy-policy/"target="_blank" rel="noopener noreferrer">Política de Privacidad</a>, que describe cómo recopilamos y utilizamos su información personal, está incorporada en estos Términos. Al aceptar estos Términos, también está aceptando nuestra Política de Privacidad.
      </p>
      <p>
        <strong>Renuncias</strong>
        <br />
        5.1 Los Servicios se proporcionan "tal cual" y "según disponibilidad". Renunciamos expresamente a cualquier garantía y condición de cualquier tipo, ya sea expresa o implícita, incluidas las garantías de comerciabilidad, idoneidad para un propósito particular y no infracción.
      </p>
      <p>
        <strong>Limitación de Responsabilidad</strong>
        <br />
        6.1 En ningún caso CriptoFénix, ni sus directores, empleados, agentes, socios, proveedores de contenido o proveedores de servicios, serán responsables en virtud de contrato, agravio, responsabilidad estricta, negligencia o cualquier otra teoría legal o equitativa con respecto a los Servicios.
      </p>
      <p>
        <strong>Cambios en los Términos de Uso</strong>
        <br />
        7.1 Podemos modificar estos Términos en cualquier momento sin previo aviso publicando los Términos revisados en nuestro Sitio web. Su uso de nuestro Sitio web constituye su aceptación vinculante de estos Términos, incluidas cualquier modificación que realicemos.
      </p>
      <p>
        <strong>Contacto</strong>
        <br />
        8.1 Si tiene alguna pregunta, inquietud o queja sobre estos Términos, por favor contáctenos en: <a href="mailto:info@criptofenix.com">info@criptofenix.com</a>
      </p>
      <p>
        Al usar nuestro Sitio web, usted reconoce que ha leído y aceptado estos Términos de Uso.
      </p>
    </div>
  );
};

export default TermsAndConditions;
