import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import  Header  from './components/Header';
import { Footer } from './components/Footer';
import { Airdrops } from './components/Airdrops';
import MetamaskLogin from './components/MetamaskLogin';
import TermsAndConditions from './components/TermsAndConditions';


function App() {
  return (
    
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MetamaskLogin />} />
        <Route path="/airdrops" element={<Airdrops />} />
        <Route path="/terminos" element={<TermsAndConditions />} />
        {/* Otras rutas de tu aplicación */}
      </Routes>
      <Footer />
    </Router>
    
  );
}

export default App;
