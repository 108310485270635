import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Header.css';
import Web3 from 'web3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faDiscord, faLinkedin, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Header() {
  const [connectedWallet, setConnectedWallet] = useState(null);
  const location = useLocation();

  async function loadWeb3() {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);

      try {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setConnectedWallet(accounts[0]);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    } else {
      console.error('No Ethereum browser extension detected. Please install Metamask.');
    }
  }

  async function connectMetamask() {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setConnectedWallet(accounts[0]);
        }
      } catch (error) {
        console.error('Error connecting to Metamask:', error);
      }
    }
  }

  function formatWalletAddress(address) {
    if (!address) return '';
    const prefix = address.slice(0, 4);
    const suffix = address.slice(-4);
    return `${prefix}...${suffix}`;
  }

  useEffect(() => {
    loadWeb3(); // Check for connection on page load
    connectMetamask(); // Attempt to connect Metamask on page load
  }, []);

  return (
    <header className="header">
      <div className="social-icons">
        <a href="https://www.youtube.com/@CriptoFenix_" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} className="social-icon youtube-icon" />
        </a>
        <a href="https://discord.gg/HXZGZFXH" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faDiscord} className="social-icon discord-icon" />
        </a>
        <a href="https://www.linkedin.com/in/criptofenix/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="social-icon linkedin-icon" />
        </a>
        <a href="https://www.tiktok.com/@criptofenix_" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTiktok} className="social-icon tiktok-icon" />
        </a>
        <a href="https://www.instagram.com/criptofenix_/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="social-icon instagram-icon" />
        </a>
      </div>
      <div className={location.pathname === '/airdrops' ? 'title-with-button' : 'title-without-button'}>
      <h1 className="header-title">
        <span className="gradient-text">Airdrops</span>
      </h1>
    </div>
    {location.pathname === '/airdrops' && (
      <div className="button-container">
        <button className="header-button" onClick={connectMetamask}>
        {connectedWallet ? `Conectado: ${formatWalletAddress(connectedWallet)}` : 'Conectar con Metamask'}
        </button>
      </div>
    )}
  </header>
  );
}

export default Header;
