import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Modals = [
  {
    id: 1,
    openButtonLabel: "Task 1",
    title: "Aprobar Gastos",
    content: "Inicia sesión en DeBank usando tu Billetera Web 3. Una vez que abras DeBank, en la parte superior derecha hay un botón \"Iniciar sesión a través de billetera web3\", haz clic en él y luego en \"Verificar\" y firma la ventana emergente en tu billetera.",
    link: "https://debank.com/",
    buttonLabel: "Aprobar Ahora",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 2,
    openButtonLabel: "Task 2",
    title: "Registrarse en DeBank L2",
    content: "Necesitarás \"Registrarte\" en DeBank L2 para realizar las siguientes tareas. En la parte superior derecha de la pantalla, debajo de tu saldo, haz clic en \"DeBank L2 no registrado\". En la siguiente página, haz clic en \"Registrarse\", elige la cadena que deseas utilizar (y que tenga $ETH disponible), haz clic en \"Siguiente\" y luego en \"Enviar solicitud\". Firma la ventana emergente que aparecerá, aprueba la transacción, introduce tu contraseña de 6 dígitos y haz clic en \"Confirmar\".",
    link: "https://debank.com/badge",
    buttonLabel: "Registrar Ahora",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 3,
    openButtonLabel: "Task 3",
    title: "Depositar en DeBank L2",
    content: "(Opcional) NFT que cuesta $96, por lo que sugerimos enviar al menos $100. Si deseas omitir el NFT, entonces $4 es suficiente. Selecciona la cadena desde la que deseas enviar > Selecciona USDT o USDC > Ingresa la cantidad del depósito > haz clic en \"Confirmar depósito\" > Autoriza la transacción en tu billetera. Una vez que el estado sea \"Completado\", pasa al siguiente paso.",
    link: "https://debank.com/badge",
    buttonLabel: "Registrar Ahora",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 4,
    openButtonLabel: "Task 4",
    title: "Crear Web3 ID, agregar correo electrónico y Twitter",
    content: "Esta tarea consta de 3 partes. 1. Crear Web3 ID 2. Conectar correo electrónico 3. Conectar Twitter. El Web3 ID tiene un costo de $96 para crear, pero como el número total es limitado, es opcional, aunque probablemente será un criterio para el airdrop.Si deseas comprarlo, haz clic en \"Pagar $96 para Crear\", ingresa un ID y haz clic en \"Pagar y Crear\". Una vez hecho esto, haz clic en \"Agregar Correo Electrónico\", ingresa tu correo electrónico y guarda. Luego, haz clic en \"Agregar ID de Twitter\", ingresa tu ID de Twitter y guarda.",
    link: "https://debank.com/settings",
    buttonLabel: "Settings Debank",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 5,
    openButtonLabel: "Task 5",
    title: "Say \Hi\"",
    content: "Queremos utilizar el servicio de Mensajería Web3 integrado. Haz clic en \"Iniciar nuevas conversaciones\">Ingresa una dirección de billetera EVM (puedes usar otra de tus propias billeteras)>Presiona enter/retorno>Escribe un mensaje>Haz clic en el botón \"$1.00\">Paga para enviar>Ingresa tu contraseña de 6 dígitos>Confirma.",
    link: "https://debank.com/hi",
    buttonLabel: "Saludar Ahora",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 6,
    openButtonLabel: "Task 6",
    title: "Publicar en \"Stream\".",
    content: "Queremos hacer una publicación en el \"Stream\". Abre el enlace, haz clic en \"Nueva Publicación\">Escribe algo>Publicar.",
    link: "https://debank.com/stream",
    buttonLabel: "Debank Stream",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 6,
    openButtonLabel: "Task 6",
    title: "Mintear Badges",
    content: "Dependiendo de tu historial de billetera, podrías ser elegible para obtener insignias. Abre la página usando el enlace a continuación y luego haz clic en el nombre de cada insignia. Si eres elegible, haz clic en \"Crear\".",
    link: "https://debank.com/badge",
    buttonLabel: "Debank Badges",
    expirationTime: 365,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  
  // Agregar más objetos para más modales...
];

const AirdropDebank = () => {
  const [showModal, setShowModal] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);

  const openModal = (modalId) => {
    setShowModal(modalId);
  };

  const closeModal = () => {
    setShowModal(null);
  };

  const toggleCompletedModal = (modalId) => {
    const now = new Date().getTime();
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.includes(modalId)
        ? prevCompletedTasks.filter((taskId) => taskId !== modalId)
        : [...prevCompletedTasks, modalId];
      if (!prevCompletedTasks.includes(modalId)) {
        const task = Modals.find((modal) => modal.id === modalId);
        if (task) {
          task.completedTime = now; 
          localStorage.setItem("completedTasksDebank", JSON.stringify(updatedTasks));
        }
      }
      return updatedTasks;
    });
  };

  const checkExpiredTasks = () => {
    const now = new Date().getTime(); 
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.filter((taskId) => {
        const task = Modals.find((modal) => modal.id === taskId);
        if (!task) return false;
        return now - task.completedTime < task.expirationTime;
      });
      localStorage.setItem("completedTasksDebank", JSON.stringify(updatedTasks));
      return updatedTasks;
    });
  };
  useEffect(() => {
    const savedCompletedTasks = JSON.parse(localStorage.getItem("completedTasksDebank") || "[]");
    setCompletedTasks(savedCompletedTasks);
    checkExpiredTasks(); 

    // Verificar tareas expiradas cuando el evento de cambio de visibilidad ocurre
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkExpiredTasks();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(checkExpiredTasks, 60 * 60 * 1000); 
    return () => {
      clearInterval(interval);
    };
  }, []);

  const closeCompletedModal = () => {
    closeModal();
  }

  return (
    <div>
      <h1 className="airdrop-Name">Debank</h1>
      <p className="airdrop-Description">
      Seguridad y control en DEFI. Rastrea fondos y contratos en tu billetera. 
      Aprueba contratos solo para actividades específicas, minimizando riesgos. 
      <p>Monitorea inversiones DEFI en tu portafolio. 
      Protege tu billetera Metamask y rastrea staking en Alpaca, PancakeSwap y Beefy. Esencial para usuarios DEFI: tranquilidad y control en un universo financiero descentralizado.</p>
      </p>
      <div className="modal-container">
        {Modals.map((modal) => (
          <div key={modal.id} className="modal-item">
            <button
              className="btn btn-primary modal-button"
              onClick={() => openModal(modal.id)}
              style={{
                width: "150px", 
                height: "40px",
                boxShadow: completedTasks.includes(modal.id)
                  ? "0 0 0 1px #00ff00" // Borde verde de 1 píxel
                  : "",
              }}
            >
              {modal.openButtonLabel}
            </button>
          </div>
        ))}
      </div>

      {showModal !== null && (
        <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">{Modals[showModal - 1].title}</h1>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Cerrar"></button>
              </div>
              <div className="modal-body">
                <strong>{Modals[showModal - 1].content}</strong>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-success ${completedTasks.includes(showModal) ? "green-button" : ""}`}
                  onClick={() => {
                    toggleCompletedModal(showModal)
                    closeCompletedModal();
                  }}
                >
                  {completedTasks.includes(showModal)
                    ? Modals[showModal - 1].uncompletedButtonLabel
                    : Modals[showModal - 1].completedButtonLabel}
                </button>
                <Link
                  to={Modals[showModal - 1].link}
                  target="_blank"
                  className="btn btn-primary"
                >
                  {Modals[showModal - 1].buttonLabel}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirdropDebank;
