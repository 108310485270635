import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import NFTContractABI from '../NFTContractABI.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Linea from '../Imagenes/Linea.png';
import zkSync from '../Imagenes/zkSync.png';
import Debank from '../Imagenes/Debank.png';
import LayerZero from '../Imagenes/LayerZero.png';


import AirdropLinea from './Acordeones/AirdropLinea';
import AirdropzkSync from './Acordeones/AirdropzkSync';
import AirdropDebank from './Acordeones/AirdropDebank';
import AirdropLayerZero from './Acordeones/AirdropLayerZero';

import './Airdrops.css';
import './modal1.css';

const Airdrops = () => {
  const navigate = useNavigate();
  const [hasNFT, setHasNFT] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const checkNFTOwnership = async (account) => {
    const web3 = new Web3(window.ethereum);
    const nftContractAddress = '0x16e2e016EA86dF3050C0C503424B6B035B74F82C';
    const abiArray = Array.isArray(NFTContractABI) ? NFTContractABI : JSON.parse(NFTContractABI);
    const nftContract = new web3.eth.Contract(abiArray, nftContractAddress);

    try {
      const nftBalance = await nftContract.methods.balanceOf(account).call();
      return parseInt(nftBalance) > 0;
    } catch (error) {
      console.error('Error al comprobar el NFT:', error);
      return false;
    }
  };

  const handleImageClick = (imageIndex) => {
    setSelectedImage(imageIndex);
  };

  useEffect(() => {
    const connectAndCheck = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length === 0) {
            navigate('/');
          } else {
            const account = accounts[0];
            const hasNFT = await checkNFTOwnership(account);
            setHasNFT(hasNFT);
            if (!hasNFT) {
              navigate('/');
            }
          }
        } catch (error) {
          console.error('Error al conectar la billetera:', error);
        }
      } else {
        console.warn('No se ha detectado una billetera Ethereum.');
        navigate('/');
      }
    };

    connectAndCheck();
  }, [navigate]);

  const airdropData = [
    { id: 'Linea', image: Linea, component: <AirdropLinea /> },
    { id: 'zkSync', image: zkSync, component: <AirdropzkSync /> },
    { id: 'Debank', image: Debank, component: <AirdropDebank /> },
    { id: 'LayerZero', image: LayerZero, component: <AirdropLayerZero /> },
    // Agrega aquí más objetos con los datos de tus airdrops
  ];

  return (
      <div className="airdrops-body">
      <div className="Background"></div>
      <div className="airdrops-wrapper">
        <div className="airdrops-container">
          {airdropData.map(({ id, image, component }) => (
            <div key={id}>
              <img
                src={image}
                alt={id}
                className={`airdrops-img ${id} Logo`}
                isSelected={selectedImage === id}
                onClick={() => handleImageClick(id)}
              />
              <div className="airdrops-alt">{id}</div>
            </div>
          ))}
          
        </div>
        {airdropData.map(({ id, component }) =>
          selectedImage === id ? component : null
        )}
        
      </div>
    </div>
  );
};
export { Airdrops };
