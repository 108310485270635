import React from 'react';
import { Link } from 'react-router-dom';
import "./Footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="terms-link">
        <Link to="/terminos">Términos & Condiciones</Link>
      </div>
      <p className="footer-text">
        Powered By: <a href="https://www.linkedin.com/in/robertfuentes-web3-developer/"
        target="_blank" rel="noopener noreferrer">Robert FR</a>
      </p>
    </footer>
  );
};

export { Footer };
