import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Modals = [
  {
    id: 1,
    openButtonLabel: "Task 1",
    title: "Agregar Optimistic a Metamask",
    content: "Utilizando LayerZero, estamos trasladando activos de una red a otra. Comenzaremos con Optimism, por lo que si aún no has agregado Optimism como una red en tu Metamask, puedes hacerlo ahora.",
    link: "https://chainlist.org/chain/10",
    buttonLabel: "Agregar Optimistic",
    expirationTime: 0, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 2,
    openButtonLabel: "Task 2",
    title: "Obtén $ETH en tu cuenta de Optimism.",
    content: "Si ya tienes $ETH en Optimism, puedes omitir este paso. Si no es así, ¡utilicemos Stargate en LayerZero para hacerlo! Puedes mover la cantidad que desees, pero te sugerimos no menos de $10.",
    link: "https://stargate.finance/transfer",
    buttonLabel: "Bridge Ahora",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 3,
    openButtonLabel: "Task 3",
    title: "Mintear NFTs in Merkly",
    content: "Merkly ha creado un producto llamado ONFTs. Su propósito es únicamente ofrecer una forma muy eficiente en costos para interactuar con diversas cadenas utilizando LayerZero. En este paso, deseas mintear y luego trasladar los ONFTs. Abre el enlace a Merkly > Conectar billetera > TurboBridge > Selecciona Optimism a la izquierda > Mintear > Confirma todas las transacciones.",
    link: "https://minter.merkly.com/",
    buttonLabel: "Mintear ONFTs",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 4,
    openButtonLabel: "Task 4",
    title: "Bridge ONFTs in Merkly",
    content: "Ahora que has introducido los ONFTs , vamos a proceder con su migración. Si te encuentras enfrentando dificultades con la característica de puente automático, no dudes en buscar asistencia para garantizar un proceso sin contratiempos.",
    link: "https://minter.merkly.com/",
    buttonLabel: "Bridge ONFTs",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 5,
    openButtonLabel: "Task 5",
    title: "Bridge Stargate.",
    content: "En esta fase, nuestra intención es llevar a cabo transferencias adicionales entre distintas redes, aprovechando la funcionalidad de Stargate. Este proceso nos permitirá mover activos de manera eficiente y seguir explorando oportunidades en diversos entornos financieros.",
    link: "https://stargate.finance/transfer",
    buttonLabel: "Bridge Ahora",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 6,
    openButtonLabel: "Task 6",
    title: "Obtén una cantidad de $STG en tu billetera.",
    content: "$STG es el token de Stargate. Puedes comprarlo y retirarlo en diversos CEX (Binance, Kucoin, etc.). Si prefieres usar un DEX, puedes adquirirlo en la red Optimism a través de Velodrome.",
    link: "https://velodrome.finance/swap?from=0x7f5c764cbc14f9669b88837ca1490cca17c31607&to=0x296f55f8fb28e498b858d0bcda06d955b2cb3f97",
    buttonLabel: "Comprar $STG",
    expirationTime: 14, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 7,
    openButtonLabel: "Task 7",
    title: "Poner en Stake $STG",
    content: "Stakear $STG muestra tu apoyo a Stargate. La cantidad y duración del Stake dependen de ti, pero por lo general, cuanto más y por más tiempo, mejor. Contribuir de esta manera fortalece la red y puede generar mayores beneficios a largo plazo.",
    link: "https://stargate.finance/stake",
    buttonLabel: "Stake $STG",
    expirationTime: 0, 
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 8,
    openButtonLabel: "Task 8",
    title: "Únete a Stargate DAO.",
    content: "Unirse a la DAO demuestra tu interés en el futuro de Stargate, lo cual podría ser un criterio para recibir futuras recompensas. Después de abrir el enlace y conectar tu billetera, haz clic en > Unirse. Y sobre todo, participa en las votaciones.",
    link: "https://snapshot.org/#/stgdao.eth",
    buttonLabel: "Unirse Ahora",
    expirationTime: 14,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 9,
    openButtonLabel: "Task 9",
    title: "Compra un poco de $USDT.",
    content: "Puedes Comprar USDT en la red Optimism desde varios DEX o CEX, como Binance, o intercambiar algunos $ETH en Sushi. Ambas opciones te permiten obtener USDT y participar en la red de Optimism para aprovechar sus ventajas de escalabilidad y eficiencia.",
    link: "https://www.sushi.com/swap?fromChainId=10&fromCurrency=NATIVE&toChainId=10&toCurrency=0x94b008aA00579c1307B0EF2c499aD98a8ce58e58&amount=&chainId=1&token0=NATIVE&token1=0xdAC17F958D2ee523a2206206994597C13D831ec7",
    buttonLabel: "Comprar USDT",
    expirationTime: 14,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 10,
    openButtonLabel: "Task 10",
    title: "Bridge $USDT",
    content: "Ahora tienes la opción de transferir tu USDT desde Optimism a otra red (como BSC) mediante Sushi. Esta operación amplía tus posibilidades de movimiento de activos y te brinda flexibilidad en diferentes ecosistemas blockchain.",
    link: "https://www.sushi.com/swap/cross-chain?chainId0=10&chainId1=56&token0=0x94b008aA00579c1307B0EF2c499aD98a8ce58e58&token1=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    buttonLabel: "Bridge $USDT",
    expirationTime: 14,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 11,
    openButtonLabel: "Task 11",
    title: "Utiliza el verificador de Merkly.",
    content: "Utilizamos Merkly en los pasos 1-4. Ahora emplearemos su herramienta Checker para asegurarnos de haber cubierto todas las bases e interactuado con la amplia gama de contratos que ofrecen, actualmente más de 170 interacciones en LayerZero. TEN EN CUENTA: el acceso a esta herramienta tiene un costo de por vida de $5 por billetera, pagados en $ETH en la red zkSync.",
    link: "https://checker.merkly.com/layerzero-checkout",
    buttonLabel: "Verificar Merkly",
    expirationTime: 0,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  {
    id: 12,
    openButtonLabel: "Task 12",
    title: "Utiliza el verificador de Merkly.",
    content: "Mediante el verificador de Merkly, podrás identificar las brechas en tu billetera actual. Como siempre, la cantidad de transacciones que realices depende completamente de ti, pero para fines de airdrop, es probable que más sea mejor.Recomendamos distribuir las transacciones en el tiempo en lugar de hacerlas todas de una vez. Es muy probable que sea más efectivo completarlas gradualmente a lo largo de días o semanas.",
    link: "https://checker.merkly.com/layerzero",
    buttonLabel: "Verificar Wallet",
    expirationTime: 7,
    completedButtonLabel: "Tarea finalizada",
    uncompletedButtonLabel: "Desmarcar tarea",
    isCompleted: false,
    completedTime: null,
  },
  // Agregar más objetos para más modales...
];

const AirdropLayerZero = () => {
  const [showModal, setShowModal] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);

  const openModal = (modalId) => {
    setShowModal(modalId);
  };

  const closeModal = () => {
    setShowModal(null);
  };

  const toggleCompletedModal = (modalId) => {
    const now = new Date().getTime(); 
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.includes(modalId)
        ? prevCompletedTasks.filter((taskId) => taskId !== modalId)
        : [...prevCompletedTasks, modalId];
      if (!prevCompletedTasks.includes(modalId)) {
        const task = Modals.find((modal) => modal.id === modalId);
        if (task) {
          task.completedTime = now; 
          localStorage.setItem("completedTasksLayerZero", JSON.stringify(updatedTasks));
        }
      }
      return updatedTasks;
    });
  };

  const checkExpiredTasks = () => {
    const now = new Date().getTime(); 
    setCompletedTasks((prevCompletedTasks) => {
      const updatedTasks = prevCompletedTasks.filter((taskId) => {
        const task = Modals.find((modal) => modal.id === taskId);
        if (!task) return false;
        return now - task.completedTime < task.expirationTime;
      });
      localStorage.setItem("completedTasksLayerZero", JSON.stringify(updatedTasks));
      return updatedTasks;
    });
  };

  useEffect(() => {
    const savedCompletedTasks = JSON.parse(localStorage.getItem("completedTasksLayerZero") || "[]");
    setCompletedTasks(savedCompletedTasks);
    checkExpiredTasks(); 

    // Verificar tareas expiradas cuando el evento de cambio de visibilidad ocurre
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkExpiredTasks();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(checkExpiredTasks, 60 * 60 * 1000); 
    return () => {
      clearInterval(interval);
    };
  }, []);

  const closeCompletedModal = () => {
    closeModal();
  }

  return (
    <div>
      <h1 className="airdrop-Name">Layer Zero</h1>
      <p className="airdrop-Description">
      LAYERZERO es una Aplicación de Usuario (UA) con endpoint ULN en cadena. Utiliza Oráculo y Relayer para transmitir mensajes entre cadenas. Al enviar un mensaje de A a B, el endpoint en A notifica a Oráculo y Relayer. Oráculo envía cabecera de bloque a B y Relayer presenta la prueba. Validado en B, el mensaje llega a dirección destino, permitiendo conexiones seguras y eficientes entre cadenas.
      </p>
      <div className="modal-container">
        {Modals.map((modal) => (
          <div key={modal.id} className="modal-item">
            <button
              className="btn btn-primary modal-button"
              onClick={() => openModal(modal.id)}
              style={{
                width: "150px", 
                height: "40px",
                boxShadow: completedTasks.includes(modal.id)
                  ? "0 0 0 1px #00ff00" 
                  : "",
              }}
            >
              {modal.openButtonLabel}
            </button>
          </div>
        ))}
      </div>

      {showModal !== null && (
        <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">{Modals[showModal - 1].title}</h1>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Cerrar"></button>
              </div>
              <div className="modal-body">
                <strong>{Modals[showModal - 1].content}</strong>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-success ${completedTasks.includes(showModal) ? "green-button" : ""}`}
                  onClick={() => {
                    toggleCompletedModal(showModal)
                    closeCompletedModal();
                  }}
                >
                  {completedTasks.includes(showModal)
                    ? Modals[showModal - 1].uncompletedButtonLabel
                    : Modals[showModal - 1].completedButtonLabel}
                </button>
                <Link
                  to={Modals[showModal - 1].link}
                  target="_blank"
                  className="btn btn-primary"
                >
                  {Modals[showModal - 1].buttonLabel}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirdropLayerZero;
